import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { loginCorporate as login } from '@/modules/auth/domain/resources/corporate/login';
import { HTTPApiError } from '@/utils/HTTPApiError';
import { getOryInstance } from '@/utils/pkg';
import { handleGetFlowError } from '@/utils/pkg/errors';
import { homeRoute } from '@/utils/routes';

export const useLoginCorporate = () => {
  const router = useRouter();
  const [error, setError] = useState(null);

  const { return_to, refresh } = router.query;

  const returnTo = `${return_to},sso,corporate`;

  const { mutateAsync: loginCorporate, isLoading } = useMutation(
    (email: string) => login({ email }),
    {
      onMutate: () => {
        setError(null);
      },
      onSuccess: async (data, email) => {
        try {
          const { data: flowData } = await getOryInstance(data).createBrowserLoginFlow({
            refresh: Boolean(refresh),
            returnTo: String(returnTo),
          });

          await getOryInstance(data).updateLoginFlow({
            flow: String(flowData?.id),
            updateLoginFlowBody: {
              method: 'oidc',
              provider: data?.provider,
              upstream_parameters: {
                hd: data?.name,
                login_hint: data?.loginHint ? email : undefined,
                prompt: data?.prompt || 'consent',
              },
            },
          });
          if (flowData?.return_to) {
            window.location.href = flowData?.return_to;
            return;
          }

          if (flowData?.return_to) {
            await router.push(homeRoute);
          }
        } catch (err) {
          await handleGetFlowError(router)(err);
          /* setError(err); */
        }
      },
      onError: async (err: HTTPApiError) => {
        await handleGetFlowError(router)(err);
        /* const errorMessage = await err.response.json();
        setError(errorMessage.error); */
      },
    }
  );

  return {
    loginCorporate,
    isLoadingLoginCorporate: isLoading,
    loginCorporateError: error,
    resetLoginCorporateError: () => setError(null),
  };
};
