import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { OAuthTypes } from '@/types';
import { callbackRoute } from '@/utils/routes';

interface OAuthButtonProps {
  ssoType: OAuthTypes;
  stateAsString?: string;
}

export const OAuthButton = ({ ssoType, stateAsString = 'type, signup' }: OAuthButtonProps) => {
  const { formatMessage } = useIntl();
  const { push } = useRouter();

  const onPressHandler = async () => {
    let queryCallback = '';
    const state = `${stateAsString},sso,${ssoType}`;

    switch (ssoType) {
      case 'google':
        queryCallback = new URLSearchParams({
          client_id: process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID,
          redirect_uri: `${window.location.origin}${callbackRoute}`,
          response_type: 'id_token token',
          scope: 'openid profile email',
          state,
          nonce: nanoid(),
        }).toString();

        push(`https://accounts.google.com/o/oauth2/v2/auth?${queryCallback}`);
        break;

      case 'apple': {
        queryCallback = new URLSearchParams({
          client_id: process.env.NEXT_PUBLIC_APPSTORE_SERVICE,
          redirect_uri: `${window.location.origin}${callbackRoute}`,
          response_type: 'code id_token',
          response_mode: 'fragment',
          state,
          nonce: nanoid(),
        }).toString();

        push(`https://appleid.apple.com/auth/authorize?${queryCallback}`);
        break;
      }
      default:
        break;
    }
  };

  return (
    <Button className="auth-sso-button" style="light" onClick={onPressHandler} fullWidth>
      <Icon name={ssoType} className="text-xl" />
      <span className="ps-2">{formatMessage({ id: `common.${ssoType}` })}</span>
    </Button>
  );
};
