import classNames from 'classnames';
import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';

import { InputHelper } from './InputHelper';

interface InputFieldProps {
  name: string;
  type?: 'text' | 'textarea' | 'password' | 'email' | 'number' | 'tel' | 'url';
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  helper?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate?: (values: any) => void;
  disabled?: boolean;
  maxLength?: number | null;
}

export const InputField = ({
  label,
  type = 'text',
  placeholder,
  helper = '',
  name,
  readOnly = false,
  required = false,
  errors = [],
  touched = false,
  disabled = false,
  maxLength = 0,
  validate = () => {},
}: InputFieldProps) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPassword, toggleShowPassword] = useState(false);

  const isPassword = type === 'password';

  const handleShowPassword = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (!inputRef?.current) return;

      toggleShowPassword((prev) => !prev);
    },
    [inputRef, toggleShowPassword]
  );

  const hasError = useMemo(
    () => !!(touched && (typeof errors === 'string' || errors?.length)),
    [touched, errors]
  );

  return (
    <label className="w-full">
      <div className="flex items-center">
        {!label ? null : <span className="text-sm font-medium mt-3 mb-1">{label}</span>}
        <InputHelper content={helper} />
      </div>
      <div className={classNames('relative input-wrapper', { 'input-with-icon-end': isPassword })}>
        <Field
          innerRef={inputRef}
          type={!isPassword ? type : showPassword ? 'text' : 'password'}
          as={type === 'textarea' ? 'textarea' : 'input'}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          validate={validate}
          errors={errors}
          className={classNames({ 'has-error': hasError })}
          aria-label={label}
          disabled={disabled}
        />
        {isPassword && (
          <button onClick={handleShowPassword} type="button">
            <Icon name={showPassword ? 'eye-password-hide' : 'eye-password-show'} />
          </button>
        )}
      </div>
      <ErrorMessage name={name}>
        {(msg) => (
          <p className="text-danger text-xs mt-1">
            {formatMessage({ id: msg }, { name, max: maxLength })}
          </p>
        )}
      </ErrorMessage>
    </label>
  );
};
