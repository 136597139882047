import { NextRouter } from 'next/router';
import { Dispatch, SetStateAction } from 'react';

import { callbackRoute } from '@/utils/routes';

// A small function to help us deal with errors coming from fetching a flow.
export function handleGetFlowError<S>(
  router: NextRouter,
  resetFlow?: Dispatch<SetStateAction<S | undefined>>
) {
  // ory sdk
  // eslint-disable-next-line
  return async (err: any) => {
    switch (err?.response?.data?.error?.id) {
      case 'session_aal2_required':
        // 2FA is enabled and enforced, but user did not perform 2fa yet!
        window.location.href = err.response?.data.redirect_browser_to;
        return;
      case 'session_already_available':
        // User is already signed in, let's redirect them home!
        console.error('User is already signed...');
        await router.push(`${callbackRoute}#state=sso,corporate,type,signin`);
        return;
      case 'session_refresh_required':
        // We need to re-authenticate to perform this action
        window.location.href = err.response?.data.redirect_browser_to;
        return;
      case 'self_service_flow_return_to_forbidden':
        // The flow expired, let's request a new one.
        console.error('The return_to address is not allowed.');
        resetFlow(undefined);
        return;
      case 'self_service_flow_expired':
        // The flow expired, let's request a new one.
        console.error('Your interaction expired, please fill out the form again.');
        resetFlow(undefined);
        return;
      case 'security_csrf_violation':
        // A CSRF violation occurred. Best to just refresh the flow!
        console.error('A security violation was detected, please fill out the form again.');
        resetFlow(undefined);
        return;
      case 'security_identity_mismatch':
        // The requested item was intended for someone else. Let's request a new flow...
        resetFlow(undefined);
        return;
      case 'browser_location_change_required':
        // Ory Kratos asked us to point the user to this URL.
        window.location.href = err.response?.data.redirect_browser_to;
        return;
    }

    switch (err.response?.status) {
      case 410:
        // The flow expired, let's request a new one.
        resetFlow(undefined);
        return;
    }
  };
}
