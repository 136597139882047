import classNames from 'classnames';
import { useRef } from 'react';

import { Icon } from '@/components/Icon';

interface InputHelperProps {
  content: string;
  placement?: 'end' | 'start';
}

export const InputHelper = ({ content, placement = 'end' }: InputHelperProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  const uniqueId = btoa(`${content}-${Math.random()}`);

  const handleMouseEnter = () => {
    if (buttonRef.current) {
      const popoverTarget = buttonRef.current.getAttribute('data-popover-target');
      const popover = document.querySelector(`[data-popover="${popoverTarget}"]`);
      if (popover) {
        popover.classList.remove('invisible');
        popover.classList.remove('opacity-0');
        popover.classList.add('visible');
      }
    }
  };

  const handleMouseLeave = () => {
    if (buttonRef.current) {
      const popoverTarget = buttonRef.current.getAttribute('data-popover-target');
      const popover = document.querySelector(`[data-popover="${popoverTarget}"]`);
      if (popover) {
        popover.classList.add('invisible');
        popover.classList.add('opacity-0');
        popover.classList.remove('visible');
      }
    }
  };

  return !content ? null : (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="relative">
      <div ref={buttonRef} data-popover-target={uniqueId} className="h-5 ms-1">
        <Icon name="info" className="text-sm" />
        <span className="sr-only">Show information</span>
      </div>
      <div
        data-popover={uniqueId}
        role="tooltip"
        className={classNames(
          'absolute top-0 ms-5 p-1 z-10 invisible inline-block text-sm text-brand-neutral-400 transition-opacity duration-300 bg-brand-neutral-900 border border-brand-neutral-700 rounded-lg shadow-sm opacity-0 w-60',
          { 'right-0': placement === 'start' }
        )}
      >
        <div className="py-1 px-2 space-y-2" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};
