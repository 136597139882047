import { NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { OAuthButton } from '@/modules/auth/presentation/atoms/OAuthButton';
import { LoginForm } from '@/modules/auth/presentation/molecules/LoginForm';
import { setQueryState, splitStateString } from '@/modules/auth/utils/getQueryState';
import { ResolveResponse, oAuthTypesVariants } from '@/types';
import { callbackRoute, loginRoute, signupLink } from '@/utils/routes';

const LoginPage: NextPage = () => {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const { return_to: returnTo } = router.query;

  const [stateAsString, setStateAsString] = useState<string>();
  const [accountResolved, setAccountResolved] = useState<ResolveResponse>();

  useEffect(() => {
    if (!router.isReady) return;

    const currentState = splitStateString(returnTo as string);

    if (returnTo && currentState?.type === 'login') return;

    const state = setQueryState('login', currentState || router.query);
    setStateAsString(state);

    const queryCallback = new URLSearchParams({
      refresh: 'true',
      return_to: `${window.location.origin}${callbackRoute}#state=${state}`,
    }).toString();

    router.push(`${loginRoute}?${queryCallback}`);
  }, [router, returnTo]);

  return (
    <>
      <h1 className="title-h2">
        {formatMessage({ id: 'login.title' })}
        <p className="text-lg font-light text-brand-neutral-300">
          {formatMessage({ id: 'login.subtitle' })}
        </p>
      </h1>

      <LoginForm setAccountResolved={setAccountResolved} accountResolved={accountResolved} />

      {!accountResolved && (
        <>
          <div className="text-xs text-center font-medium text-brand-neutral-300">
            {formatMessage({ id: 'login.or' })}
          </div>

          <div className="flex gap-2">
            {Object.values(oAuthTypesVariants).map((type) => (
              <OAuthButton key={`login-${type}`} ssoType={type} stateAsString={stateAsString} />
            ))}
          </div>
        </>
      )}

      {accountResolved === undefined && (
        <>
          <p className="text-xs text-center text-brand-neutral-300 leading-5">
            {formatMessage({ id: 'login.policy' })}
          </p>

          <div className="text-center text-sm mt-5">
            <Link href={{ pathname: signupLink, query: router.query }} role="button">
              {formatMessage({
                id: 'login.noAccount',
              })}
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default LoginPage;
