import { useMutation } from '@tanstack/react-query';
import { resolveRoute } from '@/modules/auth/domain/routes/authRoutes';
import { ResolveResponse } from '@/types';
import { requestQuery } from '@/services/request';

export const useAccountExists = () => {
  const { mutateAsync: checkAccountExists } = useMutation((data: { email: string }) =>
    requestQuery<ResolveResponse>({
      method: 'POST',
      url: resolveRoute,
      data,
      noAuth: true,
    })
  );

  return { checkAccountExists };
};
