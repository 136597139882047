import classNames from 'classnames';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Button, ButtonTypes } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { InputField } from '@/components/form/InputField';
import { useAccountExists } from '@/modules/auth/hooks/useAccountExists';
import { useLogin } from '@/modules/auth/hooks/useLogin';
import { useLoginCorporate } from '@/modules/auth/hooks/useLoginCorporate';
import { ResolveResponse, SSOTypes } from '@/types';
import { Langs } from '@/types/lang';
import { passwordForgottenRoute, signupRoute } from '@/utils/routes';

interface LoginFormProps {
  setAccountResolved: Dispatch<SetStateAction<ResolveResponse | undefined>>;
  accountResolved: ResolveResponse;
}

export const LoginForm = ({ setAccountResolved, accountResolved }: LoginFormProps) => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { login, isLoadingLogin, loginError } = useLogin();
  const { loginCorporate, loginCorporateError, resetLoginCorporateError } = useLoginCorporate();
  const { checkAccountExists } = useAccountExists();

  const [isFakeLoading, setIsFakeLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email('common.form.field.error.email.invalid'),
    password: !accountResolved
      ? Yup.string()
      : Yup.string().required('common.form.field.error.password.empty'),
  });

  const onSubmit = useCallback(
    async ({ email, password }: FormikValues, { setFieldError }: FormikHelpers<FormikValues>) => {
      resetLoginCorporateError();
      setFieldError('email', null);
      setFieldError('password', null);

      setIsFakeLoading(true);
      setTimeout(async () => {
        // Trying to login as a Majelan user
        try {
          if (accountResolved) {
            try {
              await login({
                accountType: accountResolved.type as SSOTypes,
                accountDatas: {
                  email: email.trim(),
                  password,
                },
                gcu: true,
                platform: 'web',
                lang: Langs.Fr,
                partnership: router.query?.partnerServiceId ? true : false,
              });
            } catch (error) {
              setFieldError('email', '');
              setFieldError('password', '');
            }
          } else {
            const resolveResponse = await checkAccountExists({ email });
            if (resolveResponse.type === 'corporate') {
              try {
                loginCorporate(email);
                return;
              } catch (error) {
                setFieldError('email', '');
                setFieldError('password', '');
              }
            }

            if (resolveResponse.type) {
              setAccountResolved(resolveResponse);
            } else {
              // sessionStorage to pre-fill email in SignupForm
              sessionStorage.setItem('email', email);
              router.push({ pathname: signupRoute, query: router.query });
            }
          }
          setIsFakeLoading(false);
        } catch (error) {
          setIsFakeLoading(false);
        }
      }, 2000);
    },
    [
      resetLoginCorporateError,
      accountResolved,
      login,
      router,
      checkAccountExists,
      loginCorporate,
      setAccountResolved,
    ]
  );

  const isLoading = useMemo(() => isFakeLoading || isLoadingLogin, [isFakeLoading, isLoadingLogin]);

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        isValid,
        isSubmitting,
        dirty,
        errors,
        touched,
        setValues,
        setErrors,
        setFieldError,
        setFieldValue,
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (loginCorporateError) {
            setFieldValue('email', '');
            setFieldError('email', null);
            setFieldValue('password', '');
            setFieldError('password', null);
            setIsFakeLoading(false);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [setFieldError, setFieldValue, loginCorporateError]);

        return (
          <Form className="w-full">
            <InputField
              name="email"
              label={formatMessage({
                id: 'login.form.field.email.label',
              })}
              placeholder={formatMessage({
                id: 'login.form.field.email.placeholder',
              })}
              errors={errors.email}
              touched={touched.email}
              required
              disabled={isLoading}
            />
            {accountResolved && (
              <InputField
                name="password"
                required
                label={formatMessage({
                  id: 'common.form.field.label.password',
                })}
                placeholder={formatMessage({
                  id: 'common.form.field.placeholder.password',
                })}
                errors={errors.password}
                touched={touched.password}
                type="password"
              />
            )}

            {accountResolved && (
              <div className="flex flex-col gap-4 text-xs font-normal mt-4">
                <Link href={passwordForgottenRoute}>
                  {formatMessage({ id: 'login.forgotPassword' })}
                </Link>
              </div>
            )}

            {(loginError || loginCorporateError) && (
              <div className="flex items-center text-danger text-sm my-2">
                <Icon name="close-off" className="text-sm border rounded-full me-2" />
                {formatMessage({ id: loginError || loginCorporateError })}
              </div>
            )}

            <Button
              style="primary"
              type={ButtonTypes.Submit}
              fullWidth
              disabled={!((dirty || isSubmitting) && isValid)}
              className={classNames({ 'opacity-60': !dirty || !isValid })}
              isLoading={isLoading}
            >
              {formatMessage({
                id: 'login.form.submit',
              })}
            </Button>

            {accountResolved && (
              <Button
                fullWidth
                onClick={() => {
                  setValues({ email: '', password: '' });
                  setErrors({ email: null, password: null });
                  setAccountResolved(undefined);
                }}
              >
                <Icon name="arrow-left" className="text-lg me-2" />
                <span>{formatMessage({ id: 'login.otherMethods' })}</span>
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
